export const environment = {
  production: false,
  name: 'uam-dev',
  uploadBucketEnv: 'dev',
  projectName: `WARFIGHTERS`,
  defaultTheme: 'WARFIGHTERS',
  version: '3.0.0',
  region: 'us-gov-west-1',
  root: 'http://localhost:4200/',
  url: 'https://warfighter-dev.cristlgov.com',
  endpoint: 'https://warfighter-be-dev.cristlgov.com',
  refreshEndpoint: 'https://dev-be.cristlgov.com/auth/refreshToken',
  sandboxEnabled: false,
  uamUrl: 'https://dev.cristlgov.com/',
  cookieInfo: {
    domain: '.cristlgov.com',
  },
  application: {
    admin: {
      title1: 'WARFIGHTERS',
      title2: 'TOUCHPOINT',
    },
    public: {
      title1: 'WARFIGHTERS',
      title2: 'TOUCHPOINT',
    },
  },
  amplify: {
    Auth: {
      identityPoolId: 'us-gov-west-1:045de7ba-c8f7-4f75-bcf7-8c11ac85d2cb',
      region: 'us-gov-west-1',
      userPoolId: 'us-gov-west-1_ZqVSmahc8',
      userPoolWebClientId: '3nj8hs855bisorig8aanbtn1r7',
    },
  },
};
