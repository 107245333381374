<div class="sticky-progress-bar" *ngIf="progressPercentage > 0">
  <mat-progress-bar mode="determinate" [value]="progressPercentage"></mat-progress-bar>
</div>
<div class="container-body">
  <mat-card class="survey-card">
    <mat-toolbar color="primary" class="rounded-toolbar" *ngIf="!previewId">
      <mat-toolbar-row>
        <button mat-icon-button (click)="validateBackNavigation()">
          <mat-icon>arrow_back</mat-icon>
        </button>
        <span class="spacer"></span>
        <span>Progress: {{ progressPercentage }}%</span>
      </mat-toolbar-row>
    </mat-toolbar>
    <mat-card-header>
      <mat-card-title class="survey-title">
        <div class="event-name text-lg" *ngIf="surveyData.eventName ">
          {{ surveyData.eventName }}
        </div>
        <div class="text-md">
          {{ surveyData.title }}
        </div>
      </mat-card-title>

      <mat-card-subtitle><span class="description text-md">{{
          surveyData.description
          }}</span></mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>
      <div class="image-container" *ngIf="assets">

        <img *ngIf="surveyData.image" [src]="getAssets()" class="image-preview">

      </div>
      <form>
        @for (item of surveyData.questions; let i = $index; track item.questionId) {
        <div class="question-margin">
          <app-survey-qtype-preview [question]="item" [questionNumber]="i + 1"
            (valueChanged)="onQuestionValueChanged($event)" [savedValue]="getSavedValue(item)"
            [savedFolloupValue]="getFollowUpSavedValue(item)" [isPreviewMode]="isPreviewMode"
            [assets]="assets"></app-survey-qtype-preview>
        </div>
        }
      </form>
      <hr class="separator" *ngIf="!previewId" />
      <div class="submit-button-container" *ngIf="!previewId">
        <button mat-raised-button class="btn-primary custom-button" [disabled]="errors.length || isLoading"
          (click)="submit()">
          <span class="button-content">
            <mat-spinner *ngIf="isLoading" class="custom-spinner" diameter="20"></mat-spinner>
            <mat-icon *ngIf="!isLoading">check</mat-icon>
            Complete Survey
          </span>
        </button>
        <app-error-badge [errors]="errors" [errorLabel]="'requirements'" [openFrom]="'top'"></app-error-badge>
      </div>
    </mat-card-content>
  </mat-card>
</div>
