import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppPopupService } from 'src/app/shared/services/app-popup.service';
import { SurveysService } from 'src/app/shared/services/survey.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements AfterViewInit {
  isDeveloperMode = true;
  version = environment.version;
  env = environment.name;
  isDevEnvironment = false;
  isKioskMode = false;

  constructor(
    private surveysService: SurveysService,
    private router: Router,
    private appPopup: AppPopupService,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef
  ) { }
  ngAfterViewInit(): void {
    const isKioskMode = localStorage.getItem('kioskMode');
    if (isKioskMode !== null) {
      const parsedValue = JSON.parse(isKioskMode);
      if (typeof parsedValue === 'boolean') {
        this.isKioskMode = parsedValue;
      }
    }

    this.isDevEnvironment = this.env === 'dev' ? true : false;
    this.cdr.detectChanges();
  }
  clearData() {
    this.surveysService
      .deleteAll()
      .then(() => {
        this.appPopup.info('Success', 'All data was deleted', 3000);
        this.cdr.detectChanges();
        this.goHome();
      })
      .catch((err) => {
        console.error('Error', err);
      });
  }
  goHome() {
    this.router.navigate(['/public']);
  }

  onKioskModeChange() {
    localStorage.setItem('kioskMode', JSON.stringify(this.isKioskMode));
    if (this.isKioskMode) {
      this.appPopup.warning('Kiosk-Mode', 'enabled');
    } else {
      this.appPopup.info('Kiosk-Mode', 'disabled');
    }
  }

  alertTest() {
    this.appPopup.info('Test', 'Testing popup', 1000);
  }
}
