import { createSelector } from '@ngrx/store';
import { AppState } from '../states/app.state';

export const selectUserState = (state: AppState) => state.currentUser;

export const selectCurrentUser = createSelector(selectUserState, (user) => {
  const storedDetails = localStorage.getItem('userDetails');
  const userDetails = storedDetails ? JSON.parse(storedDetails) : null;

  if (user && userDetails) {
    return {
      ...user,
      ...userDetails
    };
  }

  return user;
});

export const selectCurrentUserSub = createSelector(
  selectCurrentUser,
  (currentUser) => currentUser?.sub
);
