import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Survey, SurveyAnswer, SurveyQuestion, SurveyAnswerResult, SurveyCodeTypes } from '../../entities/survey.entity';
import { SubscriptionHelper } from '../../helpers/subscription.helper';
import { SurveyDatabase } from '../../local-db/survey.database';
import { AppPopupService } from '../../services/app-popup.service';
import { SurveysService } from '../../services/survey.service';
import { MessageModalComponent } from '../message-modal/message-modal.component';

@Component({
  selector: 'app-survey-preview',
  templateUrl: './survey-preview.component.html',
  styleUrl: './survey-preview.component.scss'
})
export class SurveyPreviewComponent {
  _previewId: string = '';
  @Input() set previewId(value: string) {
    this._previewId = value;
    this.initPreview(value);
  }
  get previewId() {
    return this._previewId;
  }
  surveyData: Survey = {} as Survey;
  progressPercentage = 0;
  answers: SurveyAnswer[] = [];
  errors: string[] = [];
  isFormValid = false;
  isLoading = false;
  isKioskMode = false;
  isPreviewMode = false;

  assets: any = {};

  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    private surveyService: SurveysService,
    public dialog: MatDialog,
    private appPopup: AppPopupService,
    private subsMgr: SubscriptionHelper,
    private db: SurveyDatabase,
    private cdr: ChangeDetectorRef
  ) { }
  ngAfterViewInit(): void {

  }

  initPreview(previewId: string): void {
    if (previewId) {
      this.isPreviewMode = true;
      this.loadPreview();
    }
  }
  loadPreview() {
    const data = JSON.parse(localStorage.getItem(this.previewId) || '{}');
    this.assets = data.assets;
    if (data) {
      this.handleSurveyData(data);
    }
  }

  loadSurvey(id: string): void {
    this.subsMgr.add(
      this.surveyService.getSurveyById(id).subscribe({
        next: (data) => this.handleSurveyData(data),
        error: (error) => this.handleError(error),
        complete: async () => {
          if (!this.isKioskMode) {
            const progress = await this.surveyService.getSavedInProgressSurvey(
              this.surveyData.eventUUID
            );
            this.answers = progress.answers;
            this.progressPercentage = this.getProgressPercentage();
          } else {
            this.answers = [];
            this.progressPercentage = 0;
          }
          this.assets = this.surveyData.assets;
          this.checkIsFormValid();
          this.cdr.detectChanges();
        },
      })
    );
  }

  getSavedValue(question: SurveyQuestion) {
    const defaultReturnValue = ['ranking', 'multiple-choose'].includes(
      question.type
    )
      ? []
      : '';
    return this.answers
      ? this.answers.find((a) => a.questionId === question.questionId)
        ?.answer || defaultReturnValue
      : defaultReturnValue;
  }

  getFollowUpSavedValue(question: SurveyQuestion) {
    return this.answers
      ? this.answers.find((a) => a.questionId === question.questionId)
        ?.followupAnswer || ''
      : '';
  }

  handleSurveyData(data: Survey): void {
    const sortedQuestions = [...data.questions].sort(
      (a, b) => a.order - b.order
    );
    this.surveyData = { ...data, questions: sortedQuestions };
    this.cdr.detectChanges();
  }

  private handleError(error: any): void {
    this.appPopup.error('An Error Ocurr', 'Try again later, Thanks!');
    console.error('Error loading survey:', error);
  }

  submit(): void {
    if (this.surveyData.accessCode) {
      this.spinner.show();
      this.isLoading = true;
      const results: SurveyAnswerResult = {
        eventUUID: this.surveyData.eventUUID,
        projectUUID: this.surveyData.project,
        surveyUUID: this.surveyData.uuid,
        code: this.surveyData.accessCode,
        answers: this.answers,
        codeType: this.surveyData.codeType?.toUpperCase(),
      };

      this.surveyService
        .submit(results)
        .then((result) => this.handleSubmitResult(result))
        .catch((error) => this.handleError(error))
        .finally(() => {
          this.isLoading = false;
        })
        .finally(() => {
          this.spinner.hide();
        });
    } else {
      this.router.navigateByUrl('/');
    }
  }

  private handleSubmitResult(result: any): void {
    if (result.submitted) {
      this.surveyService.deleteSurvey(this.surveyData.eventUUID);
      this.sayThankyou();
      if (
        this.isKioskMode &&
        this.surveyData.codeType === SurveyCodeTypes.qrcode &&
        this.surveyData.inCode
      ) {
        this.downloadSurveyByCode(
          this.surveyData.inCode,
          SurveyCodeTypes.qrcode
        );
        this.cdr.detectChanges();
      } else {
        this.surveyService.deleteProgress(this.surveyData.eventUUID);
        this.router.navigateByUrl('/public');
      }
    } else {
      if (result.errorMessage === 'ALREADY COMPLETED') {
        this.appPopup.warning('The survey had already been completed', '');
      } else if (result.errorMessage === 'SURVEY EXPIRED') {
        this.appPopup.warning(
          'Survey Expired',
          'The time to complete the questionnaire is over.'
        );
      }
      this.router.navigateByUrl('/public');
    }
  }
  downloadSurveyByCode(code: string, type: string) {
    this.surveyService
      .addSurveyByCodePromise(code, type)
      .then((result) => {
        if (result.success) {
          this.loadSurvey(result.eventUUID);
        }
      })
      .catch(() => {
        this.router.navigateByUrl('/public');
      });
  }

  sayThankyou(): void {
    this.dialog.open(MessageModalComponent, {
      width: '200px',
      maxWidth: '98%',
      data: {
        text: 'Thank you for your participation!',
        type: 'success',
        confirm: false,
      },
    });
  }
  validateBackNavigation(): void {
    if (this.isKioskMode) {
      this.router.navigateByUrl('/');
    } else {
      const dialogRef = this.dialog.open(MessageModalComponent, {
        width: '200px',
        maxWidth: '98%',
        data: {
          text: 'Are you sure you want to exit the survey without completing it?',
          type: 'warning',
          confirmText: 'Yes, Sure',
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result === 'confirm') {
          this.router.navigateByUrl('/public');
        }
      });
    }
  }
  checkIsFormValid() {
    this.errors = [];
    this.surveyData.questions.forEach((q, i) => {
      const answerObject = this.answers
        ? this.answers.find((a) => a.questionId === q.questionId)
        : null;

      let isAnswered = false;
      if (answerObject) {
        if (typeof answerObject.answer === 'string') {
          isAnswered = !!answerObject.answer.trim();
        } else if (Array.isArray(answerObject.answer)) {
          isAnswered = answerObject.answer.every((item) => !!item.trim());
        }
      }

      if (q.isRequired && q.type === 'ranking') {
        isAnswered =
          isAnswered &&
          q.options.length === answerObject?.answer.filter(Boolean).length;
      } else if (
        q.type === 'ranking' &&
        answerObject?.answer.filter(Boolean).length > 0
      ) {
        if (q.options.length !== answerObject?.answer.filter(Boolean).length) {
          this.errors.push(`Question #${i + 1} is incomplete.`);
        }
      }

      if (q.isRequired && !isAnswered) {
        this.errors.push(`Question #${i + 1} is required.`);
      }
    });

    this.isFormValid = this.errors.length === 0;
  }

  async onQuestionValueChanged(value: SurveyAnswer): Promise<void> {
    if (!this.answers) {
      this.answers = [];
    }
    const index = this.answers.findIndex(
      (a) => a.questionId === value.questionId
    );
    if (index >= 0) {
      this.answers[index] = value;
    } else {
      this.answers.push(value);
    }

    if (this.surveyData && this.surveyData.questions) {
      this.progressPercentage = this.getProgressPercentage();

      this.checkIsFormValid();
      await this.db.progress.put({
        eventUUID: this.surveyData.eventUUID,
        projectUUID: this.surveyData.project,
        surveyUUID: this.surveyData.uuid,
        code: this.surveyData.accessCode,
        answers: this.answers,
        codeType: this.surveyData.codeType,
      } as SurveyAnswerResult);
    }
  }

  getProgressPercentage() {
    if (!this.answers || !this.surveyData?.questions) {
      return 0;
    }

    const validAnswersCount = this.answers.filter(
      (a) => a.answer.length > 0
    ).length;

    return parseFloat(
      ((validAnswersCount / this.surveyData.questions.length) * 100).toFixed(1)
    );
  }

  ngOnDestroy(): void {
    this.subsMgr.destroy();
    if (this.previewId) {
      localStorage.removeItem(this.previewId);
    }
  }
  arrayBufferToImageUrl(arrayBuffer: any) {
    const blob = new Blob([arrayBuffer], { type: 'image/png' });
    const url = URL.createObjectURL(blob);
    return url;
  }
  getAssets(): any {
    if (!this.surveyData.image) return;

    const key = this.surveyData.image;
    const asset = this.assets[key as string];

    return this.isPreviewMode ? asset : this.arrayBufferToImageUrl(asset);
  }

}
