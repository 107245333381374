// user.effects.ts
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, tap, filter } from 'rxjs/operators';
import * as UserActions from '../actions/user.actions';
import { AppUserState } from '../states/app.state';
import { UAMAuthService } from 'src/app/shared/services/uam-auth.service';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private uamAuthService: UAMAuthService
  ) { }

  loadCurrentUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType('[App] Init'),
      map(() => {
        const user = this.uamAuthService.getAuthenticatedUser() as any;
        if (user && user.sub) {
          const currentUser: AppUserState = {
            sub: user.sub,
            name: user.name,
            lastName: user.family_name,
            role: user['custom:warfighter_role'],
            roleName: user['custom:warfighter_role'] || '',
            email: user.email,
            phone_number: user.phone_number,
            organization: user.organization || '',
            projects: user.projects || [],
            family_name: user.family_name,
          };
          return UserActions.setCurrentUser({ currentUser });
        }
        return UserActions.clearCurrentUser();
      })
    )
  );

  setCurrentUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.setCurrentUser),
        tap(({ currentUser }) => {
        })
      ),
    { dispatch: false }
  );

  clearCurrentUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.clearCurrentUser),
        tap(() => {
          // console.log('User cleared from store');
        })
      ),
    { dispatch: false }
  );

  handleTokenExpiration$ = createEffect(() =>
    this.uamAuthService.loggedIn.pipe(
      filter(isLoggedIn => !isLoggedIn),
      map(() => UserActions.clearCurrentUser())
    )
  );
}
