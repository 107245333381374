import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { signIn } from 'aws-amplify/auth';
import { decodeJWT } from 'aws-amplify/auth';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { customCookieService } from './cookie.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class UAMAuthService {
  public loggedIn: BehaviorSubject<boolean>;
  idToken!: string;
  refreshToken!: string;
  deviceKey!: string;
  private tokenExpirationTimer: any;
  private readonly TOKEN_REFRESH_THRESHOLD = 5 * 60; // 5 minutes before expiration

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private _httpClient: HttpClient,
    private _snackBar: MatSnackBar,
    private cookieService: customCookieService
  ) {
    this.loggedIn = new BehaviorSubject<boolean>(false);
    this.getAuthTokens();
    if (this.idToken) {
      this.setupTokenRefresh();
    }
  }

  private setupTokenRefresh() {
    if (this.tokenExpirationTimer) {
      clearTimeout(this.tokenExpirationTimer);
    }

    const decoded = this.decodeToken(this.idToken) as any;
    if (!decoded) return;

    const expirationTime = decoded.exp * 1000;
    const currentTime = Date.now();
    const timeUntilExpiration = expirationTime - currentTime;
    const refreshTime =
      timeUntilExpiration - this.TOKEN_REFRESH_THRESHOLD * 1000;

    if (refreshTime > 0) {
      this.tokenExpirationTimer = setTimeout(() => {
        this.refreshTokenFunction(this.refreshToken).subscribe({
          next: (response) => {
            if (response.IdToken) {
              this.setIdToken(response.IdToken);
              this.setupTokenRefresh(); // Configure the next refresh
            }
          },
          error: (error) => {
            console.error('Error refreshing token:', error);
            this.loggedIn.next(false);
          }
        });
      }, refreshTime);
    }
  }

  public login(username: string, password: string) {
    return signIn({ username, password });
  }

  public setIdToken(idToken: string) {
    if (idToken !== null || undefined) {
      this.idToken = idToken;
      this.checkTokenExpiration();
      this.setupTokenRefresh();
    }
  }

  public checkAuthentication(): Observable<boolean> {
    return new Observable((observer) => {
      if (this.checkTokenExpiration()) {
        observer.next(true);
        observer.complete();
      } else {
        observer.next(false);
        observer.complete();
      }
    });
  }

  public checkTokenExpiration(): boolean {
    if (!this.idToken) {
      this.loggedIn.next(false);
      return false;
    }

    const decoded = this.decodeToken(this.idToken) as any;
    if (!decoded) return false;

    const currentTime = Math.floor(Date.now() / 1000);
    const timeUntilExpiration = decoded.exp - currentTime;

    if (timeUntilExpiration < this.TOKEN_REFRESH_THRESHOLD) {
      this.refreshTokenFunction(this.refreshToken).subscribe({
        next: (response) => {
          if (response.IdToken) {
            this.setIdToken(response.IdToken);
          }
        },
        error: (error) => {
          console.error('Error refreshing token:', error);
          this.loggedIn.next(false);
        }
      });
    }

    if (decoded.exp < currentTime) {
      this.loggedIn.next(false);
      return false;
    }

    this.loggedIn.next(true);
    return true;
  }

  public decodeToken(token: string) {
    try {
      return decodeJWT(token);
    } catch (error) {
      console.error('Error decoding token:', error);
      setTimeout(() => {
        this._snackBar
          .open('Session expired, login required.', 'OK', { duration: 5000 })
          .afterDismissed()
          .subscribe(() => {
            const redirectUrl = environment.url.replace(/https?:\/\//i, '');
            this.document.location.href =
              environment.uamUrl + 'auth/login/' + redirectUrl;
          });
      }, 2000);
      return null;
    }
  }

  public getAuthTokens() {
    this.idToken = this.cookieService.get('uam-id-token') || '';
    this.refreshToken = this.cookieService.get('uam-refresh-token') || '';
    this.deviceKey = this.cookieService.get('uam-device-key') || '';
  }

  public getIdToken() {
    return this.idToken;
  }

  public getRefreshToken() {
    return this.refreshToken;
  }

  public getAuthenticatedUser() {
    if (!this.idToken) return null;
    const decode = this.decodeToken(this.idToken);
    if (decode && decode.payload) {
      return decode.payload;
    }
    return {};
  }

  public refreshTokenFunction(refreshToken: string): Observable<any> {
    // console.log("asking for refresh function to run")
    return this._httpClient.post<any>(`${environment.refreshEndpoint}`, {
      refreshToken: this.refreshToken,
      deviceKey: this.deviceKey,
    });
  }

  public clearSession() {
    if (this.tokenExpirationTimer) {
      clearTimeout(this.tokenExpirationTimer);
    }
    this.idToken = '';
    this.refreshToken = '';
    this.deviceKey = '';
    this.loggedIn.next(false);
  }
}
