import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import { SurveyQuestion } from '../../entities/survey.entity';

@Component({
  selector: 'app-survey-qtype-preview',
  templateUrl: './survey-qtype-preview.component.html',
  styleUrl: './survey-qtype-preview.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SurveyQtypePreviewComponent {
  @Input() questionNumber = 0;
  @Input() question: SurveyQuestion = {} as SurveyQuestion;

  @Input() assets: any = {};

  private imageUrlsCache: { [key: number]: string; } = {};

  @Input() isPreviewMode: boolean = false;

  _savedValue: string | string[] = '';

  @Input()
  set savedValue(value: any) {
    this._savedValue = value;
    this.selectedOptions = this.savedValue ? this.savedValue : [];
    this.cdr.detectChanges();
  }
  get savedValue() {
    return this._savedValue;
  }

  _savedFolloupValue: string = '';

  @Input()
  set savedFolloupValue(value: any) {
    this._savedFolloupValue = value;
  }
  get savedFolloupValue() {
    return this._savedFolloupValue;
  }

  @Output() valueChanged = new EventEmitter<{
    questionId: string;
    answer: any;
    followUpAnswer?: string;
  }>();

  selectedOptions: string[] = [];
  isAssetLoaded: boolean = false;

  constructor(private cdr: ChangeDetectorRef) { }
  ngOnInit(): void {
    this.isAssetLoaded = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['assets'] && changes['assets'].currentValue) {
      this.assets = changes['assets'].currentValue;
      if (Object.keys(this.assets).length) {
        this.isAssetLoaded = true;
        this.cdr.detectChanges();
      }
    }
  }

  onValueChanged(questionId: string, answer: any, followUpAnswer?: any): void {
    if (this.isPreviewMode) {
      this.isAssetLoaded = true;
    }
    const answerResult: any = { questionId, answer };

    if (followUpAnswer !== undefined) {
      answerResult.followUpAnswer = followUpAnswer;
    }
    if (!this.isPreviewMode) {
      this.valueChanged.emit(answerResult);
    }
  }

  onMultipleChooseValueChanged(
    questionId: string,
    answer: any,
    event: any
  ): void {
    const isChecked = event.target.checked;
    if (isChecked) {
      this.selectedOptions.push(answer);
    } else {
      const index = this.selectedOptions.indexOf(answer);
      if (index > -1) {
        this.selectedOptions.splice(index, 1);
      }
    }

    this.setMultipleChooseValueAndEmitEvent(questionId);
  }

  setMultipleChooseValueAndEmitEvent(questionId: string): void {
    const joinedOptions = this.selectedOptions;
    this.onValueChanged(questionId, joinedOptions, this.savedFolloupValue);
  }

  handleTextareaChange(event: Event): void {
    const value = (event.target as HTMLTextAreaElement)?.value || '';
    this.onValueChanged(
      this.question.questionId,
      value,
      this.savedFolloupValue
    );
  }
  handleFollowUpTextareaChange(event: Event): void {
    const value = (event.target as HTMLTextAreaElement)?.value || '';
    this.onValueChanged(this.question.questionId, this.savedValue, value);
  }

  getRange(): number[] {
    const maxRanking = this.question?.metadata?.maxRanking;
    const size = maxRanking !== undefined ? maxRanking : 10;
    return [...Array(size).keys()];
  }
  clearRanking() {
    this.savedValue = [];
    this.onValueChanged(
      this.question.questionId,
      this.savedValue,
      this.savedFolloupValue
    );
  }
  rankOption(selectedOption: string) {
    if (!this.savedValue.includes(selectedOption)) {
      this.savedValue.push(selectedOption);
      if (this.savedValue.length === this.question.options.length - 1) {
        const lastOption = this.question.options.find(
          (option) => !this.savedValue.includes(option)
        );
        if (lastOption) {
          this.savedValue.push(lastOption);
        }
      }

      this.updateSavedValue();
    }
  }
  getRankingForOption(option: string): number {
    return this.savedValue.indexOf(option) + 1;
  }
  private updateSavedValue() {
    this.onValueChanged(
      this.question.questionId,
      this.savedValue,
      this.savedFolloupValue
    );
  }
  getDefaultInstructionByType(type: string) {
    switch (type) {
      case 'ranking':
        return 'Please click on the following options in order of ranking, from best, to worst';
      default:
        return '';
    }
  }

  getSrc(question: any, optIndex: number) {
    if (this.imageUrlsCache[optIndex]) {
      return this.imageUrlsCache[optIndex];
    }

    const buffer = question.metadata.assets[optIndex]?.imageBuffer;
    const key = question.metadata.assets[optIndex]?.imageKey;

    if (key && !buffer) {
      const url = this.getAsset(key);

      const data = this.isPreviewMode ? url : this.arrayBufferToImageUrl(url);

      this.imageUrlsCache[optIndex] = data;
      return data ? data : '';
    } else if (buffer) {
      this.imageUrlsCache[optIndex] = buffer;
      return buffer;
    }
  }

  arrayBufferToImageUrl(arrayBuffer: any) {
    const blob = new Blob([arrayBuffer], { type: 'image/png' });
    return URL.createObjectURL(blob);
  }
  getAsset(key?: any): any {
    if (key && typeof key === 'string' && this.assets) {
      return this.assets[key];
    }
    return undefined;
  }
}
