<!-- layout.component.html -->
<div class="flex flex-col h-screen">
  <app-header [title1]="title1" [title2]="title2"></app-header>

  @if(!isPublicRoute){
  <div class="flex flex-grow overflow-hidden bg-background dark:bg-dark-background">
    <app-sidebar *ngIf="!hideSideBar" [isSlimMode]="isSlimMode"></app-sidebar>
    <div *ngIf="!hideSubSideBar">
      <ng-content select="[side-area]"></ng-content>
    </div>

    <div class="flex-grow overflow-auto bg-background dark:bg-dark-background" [ngClass]="{
        'border-background-alt dark:border-gray-800 border-l': !hideSubSideBar
      }">
      <ng-content select="[body-area]"></ng-content>
    </div>
  </div>
  } @else {
  <div class="flex w-full flex-grow overflow-auto bg-background dark:bg-dark-background">
    <router-outlet></router-outlet>
  </div>
  }

  <app-footer></app-footer>
</div>
