<div class="survey-question">
  <label class="question-label">{{ questionNumber }}. {{ question.question }}
    @if (question.isRequired) {
    <span class="required">Required</span>
    }
    @if(question.metadata?.instructions) {
    <span class="instructions">{{question.metadata?.instructions}}</span>
    } @else {

    <span class="instructions">{{getDefaultInstructionByType(question.type)}}</span>

    }
  </label>

  <div class="option-container">
    @if (question.type === 'boolean') {
    <div class="radio-button-group">
      <input type="radio" value="true" name="{{ 'question-' + questionNumber }}"
        (change)="onValueChanged(question.questionId, 'true')" [checked]="savedValue === 'true'" />
      <label>True</label>
    </div>
    <div class="radio-button-group">
      <input type="radio" value="false" name="{{ 'question-' + questionNumber }}"
        (change)="onValueChanged(question.questionId, 'false')" [checked]="savedValue === 'false'" />
      <label>False</label>
    </div>
    }

    @if (question.type === 'yesno') {
    <div class="radio-button-group">
      <input type="radio" value="yes" name="{{ 'question-' + questionNumber }}"
        (change)="onValueChanged(question.questionId, 'yes')" [checked]="savedValue === 'yes'" />
      <label>Yes</label>
    </div>
    <div class="radio-button-group">
      <input type="radio" value="no" name="{{ 'question-' + questionNumber }}"
        (change)="onValueChanged(question.questionId, 'no')" [checked]="savedValue === 'no'" />
      <label>No</label>
    </div>
    }

    @if (question.type === 'single-choose') {
    <div class="single-choose-option">
      @for (option of question.options; track i; let i = $index) {
      <div class="multiple-option">
        <input type="radio" [value]="option" class="option-input" name="{{ 'question-' + questionNumber }}"
          (change)="onValueChanged(question.questionId, option)" [checked]="savedValue === option" />
        <span class="option-text ml-2">{{ option }}</span>
      </div>
      }
    </div>
    }

    @if (question.type === 'multiple-choose') {
    <div class="multiple-choose-option">
      @for (option of question.options; track i; let i = $index) {
      <div class="multiple-option">
        <input type="checkbox" [value]="option" class="option-input" name="{{ 'question-' + questionNumber }}" (change)="
                onMultipleChooseValueChanged(
                  question.questionId,
                  option,
                  $event
                )
              " [checked]="savedValue && savedValue.includes(option)" />
        <span class="option-text ml-2">{{ option }}</span>
      </div>
      }
    </div>
    }
    <div *ngIf="question.type === 'ranking'" class="ranking">
      @for(option of question.options; track i; let i = $index) {
      <div class="ranking-option-item">
        <div class="ranking-buttons">
          @if (getRankingForOption(option)) {
          <button class="rank-button filled">
            {{ getRankingForOption(option) }}
          </button>
          } @else {
          <button (click)="rankOption(option)" class="rank-button empty"></button>
          }
        </div>
        <div class="option-text" [ngClass]="{'bold': getRankingForOption(option)}">
          {{ option }}
        </div>
      </div>
      }
      <div class="clear-ranking">
        <button (click)="clearRanking()" class="clear-button">Reset</button>
      </div>
    </div>


    @if (question.type === 'satisfactory-scale') {
    <div class="satisfactory-scale-option">
      <div class="scale-wrapper">
        @for (
        i of getRange();
        track n;
        let n = $index
        ) {
        <div class="scale-option">
          <input type="radio" [value]="n + 1" class="option-input" name="{{ 'question-' + questionNumber }}" (change)="
                  onValueChanged(question.questionId, (n + 1).toString())
                " [checked]="savedValue === (n + 1).toString()" />
          <span class="option-text">{{ n + 1 }}</span>
        </div>
        }
      </div>
    </div>
    }

    @if (question.type === 'free-text') {
    <div class="free-text-option">
      <textarea class="free-text-area" (input)="handleTextareaChange($event)" [value]="savedValue"
        [maxLength]="4000"></textarea>
    </div>
    }
  </div>



  @if (question.type === 'single-image') {
  <div class="flex flex-wrap gap-4 justify-center items-center" *ngIf="isAssetLoaded">
    <div *ngFor="let option of question.options; let i = index" class="flex flex-col items-center cursor-pointer">
      <label [attr.for]="'question-' + questionNumber + '-' + i" class="max-w-xs px-2">
        <img [src]="getSrc(question, i)"
          class="object-contain h-48 w-full rounded-md transition-shadow duration-300 ease-in-out"
          [ngClass]="{'border-shadow': savedValue === option}" alt="{{option}}">
      </label>
      <div class="flex items-center mt-2">
        <input type="radio" [id]="'question-' + questionNumber + '-' + i" [value]="option" class="option-input"
          name="{{ 'question-' + questionNumber }}" (change)="onValueChanged(question.questionId, option)"
          [checked]="savedValue === option" />
        <label [attr.for]="'question-' + questionNumber + '-' + i" class="option-text ml-2">{{ option }}</label>
      </div>
    </div>

  </div>
  }


  @if (question.metadata?.useFollowUpQuestion){
  <div class="followup-container">
    <label class="question-followup-label">{{ question.metadata?.followUpQuestionText }}</label>
    <div class="free-text-option">
      <textarea class="free-text-area" (input)="handleFollowUpTextareaChange($event)" [value]="savedFolloupValue"
        [maxLength]="4000"></textarea>
    </div>
  </div>
  }
</div>
