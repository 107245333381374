import { Component, Input } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss',
})
export class LayoutComponent {
  @Input() isSlimMode: boolean = true;
  @Input() hideSubSideBar: boolean = true;
  @Input() hideSideBar: boolean = true;
  @Input() title1: string = '';
  @Input() title2: string = '';

  isPublicRoute = false;
  constructor(router: Router) {
    router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(async (event: any) => {
        const url = event.urlAfterRedirects;
        this.isPublicRoute = url.includes('/public');
      });
  }
  ngOnInit(): void { }
}
