import { Injectable } from '@angular/core';
import { HttpAuthHelperService } from './http-auth-helper.service';
import { environment } from 'src/environments/environment';
import { Project } from '../entities/organization.entity';
import { BehaviorSubject, Observable } from 'rxjs';
import { UAMAuthService } from './uam-auth.service';

@Injectable({
  providedIn: 'root',
})
export class AppUserService {
  private baseUrl = environment.endpoint;
  private selectedProject = new BehaviorSubject<string | null>(null);

  constructor(
    private httpAuthHelperService: HttpAuthHelperService,
    private uamAuthService: UAMAuthService,
  ) { }


  setSelectedProject(project: string): void {
    this.selectedProject.next(project);
  }
  getSelectedProject(): Observable<string | null> {
    return this.selectedProject.asObservable();
  }

  async getUserDetails(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.httpAuthHelperService
        .requestPromise('GET', `${this.baseUrl}/settings/get-settings`, {})
        .then((data) => {
          if (data && data.body && data.body.response) {
            resolve(data.body.response);
          }
          reject({ success: false, message: 'Missing Settings' });
        })
        .catch((error) => {
          reject({ success: false, message: 'Missing Settings', error });
        });
    });
  }
  getAuthenticatedUser() {
    return this.uamAuthService.getAuthenticatedUser();
  }
  getOrganizationProjects(): Promise<string[]> {
    return new Promise<string[]>((resolve, reject) => {
      this.httpAuthHelperService
        .requestPromise('POST', `${this.baseUrl}/get-projects`, {})
        .then((data) => {
          const organizationProjects = data.body.response.reduce(
            (acc: Record<string, Project[]>, item: Project) => {
              if (!acc[item.organization]) {
                acc[item.organization] = [];
              }
              acc[item.organization].push(item);
              return acc;
            },
            {}
          );
          const org = Object.keys(organizationProjects);
          if (org.length === 1) {
            resolve(organizationProjects[org[0]]);
          } else {
            resolve(organizationProjects);
          }
        })
        .catch((err) => {

          reject(err);
        });
    });
  }
}
