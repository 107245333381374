import { StatusBoxModalComponent } from './../components/status-box-modal/status-box-modal.component';
import { ToastErrorDetailComponent } from './../components/toast-error-detail/toast-error-detail.component';

/* PavCon, LLC Proprietary.All rights reserved 2023 */

import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MaterialModule } from 'src/app/shared/modules/material-design.module';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpHelperService } from '../services/http-helper.service';
import { TimeAgoPipe } from '../pipes/time-ago.pipe';
import { TagInputComponent } from 'src/app/shared/components/tag-input/tag-input.component';
import { ShortDatePipe } from '../pipes/short-date.pipe';
import { ToggleSwitchComponent } from '../components/toggle-switch/toggle-switch.component';
import { ErrorBadgeComponent } from '../components/error-badge/error-badge.component';
import { BreadcrumbComponent } from '../components/breadcrumb/breadcrumb.component';
import { OrderByPipe } from '../pipes/order-by.pipe';
// import { SidebarComponent } from './../../layout/sidebar/sidebar.component';
import { SafeHtmlPipe } from '../pipes/safe-html.pipe';
import { ConfirmationModalComponent } from '../components/confirmation-modal/confirmation-modal.component';
import { MultiSelectComponent } from '../components/multi-select/multi-select.component';
import { SelectComponent } from '../components/select/select.component';
import { SelectSingleComponent } from '../components/select-single/select-single.component';
import { SkeletonLoaderComponent } from '../components/skeleton-loader/skeleton-loader.component';
import { ThemeToggleComponent } from 'src/app/layout/theme-toggle/theme-toggle.component';
import { PreviewModalComponent } from '../components/preview-modal/preview-modal.component';
import { ImageUploadComponent } from '../components/image-upload/image-upload.component';
import { UploadProgressModalComponent } from '../components/upload-progress-modal/upload-progress-modal.component';
import { PrintPreviewModalComponent } from '../components/print-preview-modal/print-preview-modal.component';
import { LongDateTimePipe } from '../pipes/long-date-time.pipe';
import { TruncatePipe } from '../pipes/truncate.pipe';
import { LucideIconsModule } from './lucide-icons.module';
import { DynamicFormComponent } from '../components/dynamic-form/dynamic-form.component';
import { DynamicTableComponent } from '../components/dynamic-table/dynamic-table.component';
import { BadgeComponent } from '../components/badge/badge.component';
import { TooltipComponent } from '../components/tooltip/tooltip.component';
import { TooltipDirective } from '../directives/tooltip.directive';
import { FieldTableComponent } from '../components/field-table/field-table.component';
import { FieldDateComponent } from '../components/field-date/field-date.component';
import { FieldInputSelectComponent } from '../components/field-input-select/field-input-select.component';
import { FieldYesnoComponent } from '../components/field-yesno/field-yesno.component';
import { FieldInputTextComponent } from '../components/field-input-text/field-input-text.component';
import { FieldSingleChoiceComponent } from '../components/field-single-choice/field-single-choice.component';
import { FieldMultipleChoiceComponent } from '../components/field-multiple-choice/field-multiple-choice.component';
import { FieldFileUploadComponent } from '../components/field-file-upload/field-file-upload.component';
import { HelpTooltipComponent } from '../components/help-tooltip/help-tooltip.component';
import { ExtensionInputComponent } from '../components/extension-input/extension-input.component';
import { ToggleSwitchV2Component } from '../components/toggle-switch-v2/toggle-switch-v2.component';
import { TimeToFromPipe } from '../pipes/time-to-from.pipe';
import { TimeUntilPipe } from '../pipes/time-until.pipe';
import { TagsManagerComponent } from '../components/tags-manager/tags-manager.component';
import { TagComponent } from '../components/tag/tag.component';
import { NgxColorsModule } from 'ngx-colors';

import {
  IConfig,
  NgxMaskDirective,
  NgxMaskPipe,
  provideEnvironmentNgxMask,
} from 'ngx-mask';
import { UtcDatePipe } from '../pipes/utc-date.pipe';
import { UtcTimePipe } from '../pipes/utc-time.pipe';
import { ExpandibleModalComponent } from '../components/expandible-modal/expandible-modal.component';
import { DynamicTableSettingsComponent } from '../components/dynamic-table-settings/dynamic-table-settings.component';
import { ScrollbarDirective } from '../directives/scrollbar.directive';
import { PdfPageComponent } from '../components/pdf-page/pdf-page.component';
import { ResolvePipe } from '../pipes/resolve.pipe';
import { ProgressModalComponent } from '../components/progress-modal/progress-modal.component';
import { SurveyPreviewComponent } from '../components/survey-preview/survey-preview.component';
import { SurveyQtypePreviewComponent } from '../components/survey-qtype-preview/survey-qtype-preview.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialModule,
    LucideIconsModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgxColorsModule,

  ],
  exports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    TimeAgoPipe,
    TagInputComponent,
    ShortDatePipe,
    ToggleSwitchComponent,
    ErrorBadgeComponent,
    BreadcrumbComponent,
    OrderByPipe,
    SafeHtmlPipe,
    ConfirmationModalComponent,
    MultiSelectComponent,
    SelectComponent,
    SelectSingleComponent,
    SkeletonLoaderComponent,
    ToastErrorDetailComponent,
    ThemeToggleComponent,
    PreviewModalComponent,
    ImageUploadComponent,
    UploadProgressModalComponent,
    StatusBoxModalComponent,
    PrintPreviewModalComponent,
    LongDateTimePipe,
    TruncatePipe,
    LucideIconsModule,
    TooltipComponent,
    DynamicFormComponent,
    DynamicTableComponent,
    BadgeComponent,
    TooltipDirective,
    FieldTableComponent,
    FieldDateComponent,
    FieldInputSelectComponent,
    FieldYesnoComponent,
    FieldInputTextComponent,
    FieldSingleChoiceComponent,
    FieldMultipleChoiceComponent,
    FieldFileUploadComponent,
    HelpTooltipComponent,
    ExtensionInputComponent,
    ToggleSwitchV2Component,
    TimeUntilPipe,
    TimeToFromPipe,
    NgxMaskDirective,
    NgxMaskPipe,
    TagsManagerComponent,
    TagComponent,
    NgxColorsModule,
    UtcDatePipe,
    UtcTimePipe,
    DynamicTableSettingsComponent,
    ScrollbarDirective,
    PdfPageComponent,
    ResolvePipe,
    SurveyPreviewComponent,
    SurveyQtypePreviewComponent,
  ],
  declarations: [
    TimeAgoPipe,
    TagInputComponent,
    ShortDatePipe,
    ToggleSwitchComponent,
    ErrorBadgeComponent,
    BreadcrumbComponent,
    OrderByPipe,
    SafeHtmlPipe,
    ConfirmationModalComponent,
    MultiSelectComponent,
    SelectComponent,
    SelectSingleComponent,
    SkeletonLoaderComponent,
    ToastErrorDetailComponent,
    ThemeToggleComponent,
    PreviewModalComponent,
    ImageUploadComponent,
    UploadProgressModalComponent,
    StatusBoxModalComponent,
    PrintPreviewModalComponent,
    LongDateTimePipe,
    TruncatePipe,
    DynamicFormComponent,
    DynamicTableComponent,
    BadgeComponent,
    TooltipComponent,
    TooltipDirective,
    FieldTableComponent,
    FieldDateComponent,
    FieldInputSelectComponent,
    FieldYesnoComponent,
    FieldInputTextComponent,
    FieldSingleChoiceComponent,
    FieldMultipleChoiceComponent,
    FieldFileUploadComponent,
    HelpTooltipComponent,
    ExtensionInputComponent,
    ToggleSwitchV2Component,
    TimeUntilPipe,
    TimeToFromPipe,
    TagsManagerComponent,
    TagComponent,
    UtcDatePipe,
    UtcTimePipe,
    ExpandibleModalComponent,
    DynamicTableSettingsComponent,
    ScrollbarDirective,
    PdfPageComponent,
    ResolvePipe,
    ProgressModalComponent,
    SurveyPreviewComponent,
    SurveyQtypePreviewComponent,
  ],
  providers: [HttpHelperService, provideEnvironmentNgxMask(maskConfig)],
})
export class SharedModule { }
/* PavCon, LLC Proprietary.All rights reserved 2022 */
