import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthStateService {
  private autoRefreshEnabled = new BehaviorSubject<boolean>(true);
  public autoRefreshEnabled$ = this.autoRefreshEnabled.asObservable();

  disableAutoRefresh() {
    this.autoRefreshEnabled.next(false);
  }

  enableAutoRefresh() {
    this.autoRefreshEnabled.next(true);
  }

  isAutoRefreshEnabled(): boolean {
    return this.autoRefreshEnabled.value;
  }
}
