<div class="relative" [ngClass]="class">
  <button (click)="toggleDropdown()"
    class="px-1 py-1 bg-white dark:bg-dark-panel dark:border-none dark:text-gray-400 text-gray-600 rounded-md focus:outline-none flex items-center justify-between hover:text-gray-900 w-full {{buttonClass}}">
    <div class="pl-2 flex flex-row" [ngClass]="{'capitalize': capitalized}">
      <ng-content select="[select-icon]" class="mr-2"></ng-content>
      <span class="mx-2" *ngIf="labelInSelect">{{label}}:</span>
      <span [ngClass]="{'text-gray-800 dark:text-gray-300 font-semibold': labelInSelect}">{{ replace(getDisplayText()) |
        truncate: 32}}</span>
    </div>
    <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
    </svg>
  </button>

  <div *ngIf="dropdownOpen" class="absolute mt-[48px] w-full rounded-md bg-white shadow-lg z-40 dark:bg-dark-panel"
    (click)="$event.stopPropagation()">
    <ul class="divide-y divide-gray-200 dark:divide-gray-300 dark:text-gray-400 text-gray-700">
      <li *ngFor="let option of options" (click)="selectOption(option)"
        class="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-gray-300 cursor-pointer"
        [class.bg-gray-200]="isOptionSelected(option)" [class.dark:bg-gray-600]="isOptionSelected(option)">
        <svg *ngIf="isOptionSelected(option)" class="w-4 h-4 text-blue-500 mr-2" fill="none" stroke="currentColor"
          viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
        </svg>
        <span [ngClass]="{'capitalize': capitalized}">{{ replace(getOptionLabel(option)) }}</span>
      </li>
    </ul>
  </div>
</div>
