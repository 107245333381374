import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { UAMAuthService } from '../shared/services/uam-auth.service';
import { environment } from 'src/environments/environment';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IsAdminGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: UAMAuthService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.authService.checkAuthentication().pipe(
      map((isAuthenticated) => {
        if (!isAuthenticated) {
          window.location.href = environment.uamUrl;
          return false;
        }

        const userDetails = this.authService.getAuthenticatedUser();
        if (
          userDetails &&
          (userDetails['custom:warfighter_role'] === 'pavcon' ||
            userDetails['custom:warfighter_role'] === 'pavcon-user' ||
            userDetails['custom:warfighter_role'] === 'organization-admin' ||
            userDetails['custom:warfighter_role'] === 'organization-user')
        ) {
          return true;
        }

        this.router.navigate(['/']);
        return false;
      }),
      catchError(() => {
        this.router.navigate(['/error']);
        return of(false);
      })
    );
  }
}
