import { createReducer, on } from '@ngrx/store';
import { setCurrentUser } from '../actions/user.actions';
import { AppUserState } from '../states/app.state';

export const initialState: AppUserState = {
  sub: '',
  name: '',
  role: '',
  roleName: '',
  organization: '',
  projects: [],
  email: '',
  phone_number: '',
  family_name: '',
  lastName: '',
};

export const currentUserReducer = createReducer(
  initialState,
  on(setCurrentUser, (state, { currentUser }) => {
    const storedDetails = localStorage.getItem('userDetails');
    const userDetails = storedDetails ? JSON.parse(storedDetails) : null;

    return {
      ...state,
      ...currentUser,
      ...(userDetails || {}),
    };
  })
);
