import { NgModule } from '@angular/core';
import {
  LucideAngularModule,
  Home,
  User,
  Bell,
  FolderCog,
  ChevronDown,
  Edit,
  FileText,
  HelpCircle,
  Info,
  LogOut,
  Menu,
  Plus,
  Search,
  Settings,
  Star,
  Hammer,
  Wrench,
  Pencil,
  Trash2,
  Fullscreen,
  CircleX,
  CircleGauge,
  Gauge,
  LayoutDashboard,
  Bug,
  Flag,
  Activity,
  HandCoins,
  CircleDollarSign,
  Landmark,
  ListPlus,
  FileClock,
  PanelsTopLeft,
  Library,
  BookText,
  Eye,
  Loader,
  Save,
  Replace,
  X,
  RectangleEllipsis,
  BookDashed,
  Inbox,
  ArrowLeftToLine,
  ArrowRightToLine,
  Filter,
  Contact,
  Store,
  CalendarDays,
  Calendar,
  AreaChart,
  TextCursorInput,
  ClipboardList,
  Timer,
  Check,
  OctagonX,
  ImageUp,
  Delete,
  Grip,
  EllipsisVertical,
  Copy,
  PencilLine,
  SquareX,
  ArrowDown,
  ArrowUp,
  ChevronLeft,
  ChevronRight,
  Group,
  BetweenVerticalStart,
  ListX,
  Zap,
  Columns3,
  OctagonPause,
  AtSign,
  SearchX,
  Phone,
  Link,
  BoxSelect,
  ArrowRight,
  ShieldAlert,
  BookA,
  ArrowUpFromLine,
  Upload,
  ThumbsUp,
  SquarePercent,
  Shapes,
  Maximize2,
  ClipboardType,
  CloudUpload,
  Download,
  CheckCheck,
  Printer,
  MonitorCheck,
  File,
  FileX,
  Clock,
  LayoutGrid,
  Lock,
  BarChart2,
  Users,
} from 'lucide-angular';

@NgModule({
  imports: [
    LucideAngularModule.pick({
      LayoutGrid,
      Users,
      BarChart2,
      Lock,
      Clock,
      Home,
      User,
      Settings,
      Bell,
      FileText,
      LogOut,
      Menu,
      Hammer,
      ChevronDown,
      FolderCog,
      Wrench,
      Info,
      Star,
      Search,
      Plus,
      Edit,
      HelpCircle,
      Pencil,
      Trash2,
      Fullscreen,
      CircleX,
      CircleGauge,
      Gauge,
      LayoutDashboard,
      Bug,
      Flag,
      Activity,
      HandCoins,
      CircleDollarSign,
      Landmark,
      ListPlus,
      FileClock,
      PanelsTopLeft,
      Library,
      BookText,
      Eye,
      Loader,
      Save,
      Replace,
      RectangleEllipsis,
      X,
      BookDashed,
      Inbox,
      ArrowLeftToLine,
      ArrowRightToLine,
      Filter,
      Contact,
      Store,
      CalendarDays,
      Calendar,
      AreaChart,
      TextCursorInput,
      ClipboardList,
      Timer,
      Check,
      OctagonX,
      ImageUp,
      Grip,
      Delete,
      EllipsisVertical,
      Copy,
      SquareX,
      PencilLine,
      ArrowUp,
      ArrowDown,
      ChevronLeft,
      ChevronRight,
      Group,
      ListX,
      BetweenVerticalStart,
      Zap,
      Columns3,
      OctagonPause,
      AtSign,
      Phone,
      Link,
      SearchX,
      BoxSelect,
      ArrowRight,
      ShieldAlert,
      BookA,
      ThumbsUp,
      Upload,
      Shapes,
      SquarePercent,
      ArrowUpFromLine,
      Maximize2,
      ClipboardType,
      CloudUpload,
      Download,
      CheckCheck,
      Printer,
      MonitorCheck,
      File,
      FileX,
    }),
  ],
  exports: [LucideAngularModule],
  declarations: [],
  providers: [],
})
export class LucideIconsModule { }
