<aside class="w-[250px] block bg-black dark:bg-dark-sidebar no-print shadow-sidebar" style="height: 100vh">
  <div class="flex-1 overflow-auto p-4 flex flex-col justify-between h-full">
    <nav class="flex flex-col w-full h-full justify-start my-6">

      @if (haveAccess(navigation[0])) {
      <div class="w-full mb-2">
        <a class="sidebar-item flex items-center space-x-3" (click)="onNavigationClick(navigation[0])">
          <lucide-icon name="home" class="text-white w-5 h-5"></lucide-icon>
          <span class="text-white text-sm font-medium">Home</span>
        </a>
      </div>
      }


      <div class="my-6 border-b border-white/20"></div>


      @for (category of categories; track category) {
      @if (hasCategoryItems(category)) {
      <div class="mb-8">

        <div class="mb-3 px-3">
          <span class="text-xs font-semibold text-white/80 uppercase tracking-wider">
            {{ getCategoryTitle(category) }}
          </span>
          <div class="mt-2 bg-white/10" style="height: 1px;"></div>
        </div>

        @for (nav of getNavigationByCategory(category); track nav.name) {
        @if (haveAccess(nav)) {
        <div class="w-full mb-2">
          <a class="sidebar-item flex items-center space-x-3" [class.active]="isActive(nav.routes)"
            (click)="onNavigationClick(nav)">
            <lucide-icon [name]="nav.icon" class="text-white w-5 h-5"></lucide-icon>
            <span class="text-white text-sm font-medium whitespace-nowrap">{{ nav.name }}</span>
          </a>
        </div>
        }
        }
      </div>
      }
      }
    </nav>
  </div>
</aside>
